import {React, useState, useEffect} from 'react';
import {Link, useLocation} from "react-router-dom";
import AllProjectsTable from '../../components/project/AllProjectsTable';


import PageTitle from "../../components/PageTitle";
import axiosInstance from '../../config/axiosInstance';

export default function AllProjects(){
    const [projects, setProjects] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [brands, setBrands] = useState("");

    const [status, setStatus] = useState("");
    const [company, setCompany] = useState("");
    const [brand, setBrand] = useState("");

    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
        getProjects();
        getBrands();
    }, [])

    useEffect(() => {
        getProjects();
        if(status !== "" || brand !== "" || company !== ""){
            setFiltered(true)
        }
    }, [status, brand, company])

    

    const getProjects = async () => {
        setIsLoading(true)
        try {
            const response = await axiosInstance.get(`clients/projects/filter?status=${status}&company=${company}&brand=${brand}`);
            console.log(response.data.data)
            setProjects(response.data.data)
        } catch(error){
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const getBrands = async () => {
        try {
            const response = await axiosInstance.get(`clients/brands`);
            console.log(response.data.data.brands);
            setBrands(response.data.data.brands);
        } catch(error){
            console.log(error)
        }
    }

    const handleFilter = (e, type) => {
        if(type === "status"){
            if(e.target.value === "none"){
                setStatus("");
            } else {
                setStatus(e.target.value)
            }
        }

        if(type === "brand"){
            if(e.target.value === "none"){
                setBrand("");
            } else {
                setBrand(e.target.value);
            }
        }
    }

    const handleClearFilter = () => {
        setStatus("");
        setBrand("");
        setCompany("");
        setFiltered(false)
    }

    return(
        <>

        <PageTitle title={"Projects"} />

        <div className="flex justify-between">
            <h2 className="font-bold text-xl">Projects</h2>

            
            
            <div className='flex justify-between items-center gap-3'>

                <div>
                {filtered && <span className='inline-block w-24 text-right text-sm cursor-pointer underline text-blue-900' onClick={handleClearFilter} >clear filter</span>}
                </div>
            
                <select className='block w-full p-2 border rounded-md' onChange={(e) => handleFilter(e, 'status')} value={status}>
                    <option value="none">Select Status</option>
                    <option value="0">Pending</option>
                    <option value="1">In progress</option>
                    <option value="2">Ready for feedback</option>
                    <option value="3">Completed</option>
                </select>

                <select className='block w-full p-2 border rounded-md' onChange={(e) => handleFilter(e, 'brand')} value={brand} >
                    <option value="none">Select Brand</option>
                    {brands.length > 0 ? (
                        brands.map(brand => {
                            return(
                                <option value={`${brand.id}`}>{brand.brand_name}</option>
                            )
                        })
                    ) : (
                        <option>No brands selected</option>
                    )}
                </select>

                
            </div>
        </div>


        

        {!isLoading && <AllProjectsTable projects={projects} isLoading={isLoading} />}
        
        
        </>
    )
}